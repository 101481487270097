<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('权限管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </el-col>
    <el-col :span="12" style="text-align:right;">
      <el-button-group>
        <el-button type="success" @click="getPermissions()" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
      </el-button-group>
      
    </el-col>
  </el-row>
  
  <div class="table-wrap">
    <el-table :data="permissions" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
      <el-table-column type="selection"  min-width="55" :fixed="!isMinScreen"></el-table-column>
      <el-table-column prop="id" :label="msg('ID')" min-width="70" :fixed="!isMinScreen"></el-table-column>
      <el-table-column prop="label" :label="msg('权限名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pattern" :label="msg('表达式')" min-width="120"></el-table-column>
      <el-table-column prop="url" :label="msg('URL')" min-width="80"></el-table-column>
      <el-table-column prop="disabled" :label="msg('状态')" min-width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.disabled === 1 ? 'warning' : 'success'" disable-transitions>{{scope.row.disabled == 1 ? "禁用":"正常"}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="msg('说明')" min-width="80"></el-table-column>
    </el-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page="currentPage"
      :page-sizes="[10, 20,50, 100, 200, 300, 400]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen">
    <per-edit :permission="currPer" v-on:closeDg="closeEditDialog" ref="perEdit"/>
  </el-dialog>

</div>
</template> 

<script>
// @ is an alias to /src
import PerEdit from "./PermissionEdit.vue";

export default {
  name: 'permission',
  components: {
    PerEdit: PerEdit,
  },
  mounted:function(){
    this.getPermissions();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
        },
        currentPage: 1,   //分页的当前页
        permissions: [],   //表格数据列表
        dglogTitle: this.msg('编辑权限'),
        dgGrantMenuTitle: this.msg('分配权限'),
        dgGrangMenuVisiable: false,
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currPer: {id: null, label: null, pattern: null,url:null,disabled:null,remark:null},   //当前列表中选中的id,
        dialogTableVisible:false,
        dgGrantPermissionVisiable: false,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 250;
        }
    }
  },
  methods: {
    getPermissions: function(){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      this.axios.post(this.$kit.api.permList, param).then(function (result) {
        if(result.status){
          if(_this.permissions.length>0)
            _this.permissions.splice(0, _this.permissions.length);
          
          _this.total = result.data.recordsTotal;
          var perDatas = result.data.data;

          for(var i=0; i<perDatas.length; i++){
            var item = perDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.permissions.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getPermissions();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getPermissions();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getPermissions();
    },
    add(){  //添加
      this.currPer = {id: null, label: null, pattern: null,url:null,disabled:null,remark:null};
      this.dglogTitle = this.msg('添加权限');
      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
       this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      this.currPer = selectes[0];
      this.dglogTitle = this.msg("编辑权限：")+this.currPer.label;
      var perEdit = this.$refs.perEdit;
      
      this.dialogTableVisible = true;
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getPermissions();
      }
      this.dialogTableVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map(a => a.id);
      var _this = this;

      this.$confirm(this.msg('确定要删除选定权限吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.permDelete, ids)
            .then(function(result){
              _this.$message(result.msg || this.msg('删除成功'));
              _this.getPermissions();
          });
        });
    },
    
  }
}
</script>

<style class="scss" scoped>


</style>
